<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <h1 style="display: none">{{ $t('general.searchPage') }}</h1>
    <div class="container">
      <div class="search--container">
        <search-profile
          v-if="
            ($route.name === 'search-user-listing' || $route.name === 'search-agent-catalog') &&
            $mq === 'xs'
          "
        ></search-profile>
        <listing-partner-header v-if="$route.name === 'listing-partner'"></listing-partner-header>
        <search-header></search-header>
        <div class="d-flex justify-space-between">
          <div class="left--col">
            <search-content></search-content>
          </div>
          <div class="right--col" :class="{ active: isActive }">
            <search-filters></search-filters>
          </div>
        </div>
      </div>
    </div>
    <popup-compare></popup-compare>
    <modal-compare></modal-compare>
    <script v-html="jsonldBreadcrumb" type="application/ld+json"></script>
    <script v-html="jsonld" type="application/ld+json"></script>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import SearchHeader from '@/components/search/header';
import SearchContent from '@/components/search/content';
import SearchFilters from '@/components/search/filters';
const SearchProfile = () => import('@/components/search/search-profile');
const ListingPartnerHeader = () => import('@/components/search/listing-partner-header');
const PopupCompare = () => import('@/components/search/compare-popup');
const ModalCompare = () => import('@/components/search/modals/compare-modal');

import DefaultHeadMixin from '@/mixins/defaultHead';
import HelperMixin from '@/mixins/helpers';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'search-page',
  mixins: [DefaultHeadMixin, HelperMixin],
  components: {
    DefaultLayout,
    SearchHeader,
    SearchContent,
    SearchFilters,
    SearchProfile,
    ListingPartnerHeader,
    PopupCompare,
    ModalCompare,
  },
  computed: {
    ...mapState({
      isActive: (state) => state.v2.search.activeFilterSidebar,
      listingType: (state) => state.v2.search.listingType,
      listings: (state) => state.v2.search.listings,
    }),
    ...mapGetters({
      activePropertyType: 'v2/search/activePropertyType',
    }),
    typeString() {
      return this.listingType === 'R' ? this.$t('general.rent') : this.$t('general.sell');
    },
    propertyTypeString() {
      return this.activePropertyType && this.activePropertyType.id
        ? this.activePropertyType.name
        : '';
    },
    ownerName() {
      const owner = this.$store.state.v2.profile.owner;
      return owner ? owner.fullname : '';
    },
    breadcrumbs() {
      if (this.$route.name === 'search') {
        return [
          {
            text: this.$t('breadcrumb.homepage'),
            to: '/',
          },
          {
            text: `${this.typeString} ${this.propertyTypeString}`,
            to: this.$router.currentRoute.path,
          },
        ];
      } else if (this.$route.name === 'search-user-listing') {
        return [
          {
            text: this.$t('breadcrumb.homepage'),
            to: '/',
          },
          {
            text: this.ownerName,
            to: this.$router.currentRoute.path,
          },
        ];
      } else if (this.$route.name === 'listing-partner') {
        const name = this.$store.state.v2.listingPartner.name;
        return [
          {
            text: this.$t('breadcrumb.homepage'),
            to: '/',
          },
          {
            text: `${name}`,
            to: this.$router.currentRoute.path,
          },
        ];
      }
      return [];
    },
    jsonldBreadcrumb() {
      let baseUrl = this.$store.state.global.baseUrl;
      let text = `${this.typeString} ${this.propertyTypeString}`;
      if (this.$route.name === 'search-user-listing') {
        text = this.ownerName;
      }
      return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            name: this.$t('breadcrumb.homepage'),
            item: baseUrl + '/',
          },
          {
            '@type': 'ListItem',
            position: 2,
            name: text,
            item: baseUrl + this.$router.currentRoute.path,
          },
        ],
      };
    },
    jsonld() {
      let baseUrl = this.$store.state.global.baseUrl;
      let path = this.$router.currentRoute.path;
      const listingType2 = this.listingType === 'R' ? 'Disewakan' : 'Dijual';
      const listingType3 = this.listingType === 'R' ? 'sewa' : 'beli';
      const listingType4 = this.listingType === 'R' ? 'Sewa' : 'Beli';
      const activePropertyTypes = this.$store.state.v2.search.activePropertyTypes;
      const propertyType = activePropertyTypes && activePropertyTypes.length > 0 ? activePropertyTypes[0] : '';
      const searchTerm = this.$store.state.v2.search.searchTerm;
      const property =
        propertyType && propertyType.name !== null ? propertyType.name.toLowerCase() : 'properti';
      const location = searchTerm
        ? searchTerm
        : 'Jakarta, Bogor, Depok, Tangerang, Bekasi dan Seluruh Indonesia';
      let title = listingType4 + ' ' + property + ' di ' + location + ' dari Rentfix.';

      let description =
        listingType2 +
        ' ' +
        property +
        ' di ' +
        location +
        ' dari Rentfix dengan harga terjangkau & murah. Cari dan ' +
        listingType3 +
        ' ' +
        property +
        ' di ' +
        location +
        ' yang paling tepat untuk Anda.';

      let itemList = [],
        i = 1;
      for (let listing of this.listings) {
        listing.isActive = true;
        itemList.push({
          '@type': 'ListItem',
          position: i,
          item: this.getListingJsonld({
            baseUrl: baseUrl,
            path: listing.links.detail,
            listing: listing,
            galleries: listing.photos,
          }),
        });
        i++;
      }

      return {
        '@type': 'RealEstateListing',
        description: description,
        name: title,
        url: baseUrl + path,
        mainEntity: {
          '@type': 'ItemList',
          itemListElement: itemList,
        },
      };
    },
  },
  async fetch({ store, route, query, error }) {
    console.log(
      'Panggil get search listing dari fetch di search/index.vue dengan isi route: ',
      route,
    );

    store.commit('global/SET_LOADING', true);

    await store.dispatch('v2/search/loadListingTypeFromRoute', {
      query: query,
      params: route.params,
    });
    await store.dispatch('v2/search/loadSearchTypeFromRoute', {
      query: query,
      params: route.params,
    });
    console.log('SEARCH TYPE: ', store.state.v2.search.searchType);

    await store.dispatch('v2/search/loadIsOfficialPartnerFromRoute', {
      query: query,
      params: route.params,
    });
    console.log('IS OFFICIAL PARTNER: ', store.state.v2.search.isOfficialPartner);

    store.commit('v2/search/set_listings', { data: [], meta: null });
    store.commit('v2/search/set_premier_listings', []);
    store.commit('v2/search/set_projects', { data: [], meta: null });
    store.commit('v2/search/SET_OFFICIAL_PARTNER_AREAS_DATA', []);
    store.commit('v2/search/SET_OFFICIAL_PARTNER_AREAS_META', null);

    let allPromises = [];
    const categoryId = parseInt(query.property_category);
    await store.dispatch('v2/search/setSelectedCategoryId', {
      payload: categoryId,
      query: query,
    });

    await store.dispatch('v2/search/loadListingTypeFromRoute', {
      query: query,
      params: route.params,
    });

    // if (route.name === 'listing-partner') {
    //   allPromises.push(
    //     store.dispatch('v2/search/getSearchListingPartner', { query: query, params: route.params }),
    //   );
    // } else {
    //   if (store.state.v2.search.searchType === 2) {
    //     allPromises.push(
    //       store.dispatch('v2/search/getSearchListing', { query: query, params: route.params }),
    //     );
    //     // allPromises.push(store.dispatch('v2/search/getGeoJsonListings', query));
    //   } else {
    //     allPromises.push(
    //       store.dispatch('v2/search/getProjectPartners', { query: query, params: route.params }),
    //     );
    //   }
    // }
    if (!store.state.v2.search.isOfficialPartner) {
      allPromises.push(store.dispatch('v2/masters/getCitiesByProvinceId', null));
      if (store.state.v2.search.listingType === 'R') {
        allPromises.push(store.dispatch('v2/masters/getPropertyTypesByCategory', categoryId));
        allPromises.push(store.dispatch('v2/masters/getRentTypesByCategory', categoryId));
        allPromises.push(store.dispatch('v2/masters/getRentPeriodsByCategory', categoryId));
      } else {
        allPromises.push(store.dispatch('v2/masters/getPropertyTypesByCategory', categoryId));
      }

      try {
        await Promise.all(allPromises);
        await store.dispatch('v2/search/getSearchListing', { query: query, params: route.params });
      } catch (e) {
        error(new Error(e.message), 404);
      }
    } else {
      allPromises.push(store.dispatch('v2/masters/getPropertyTypesBySearchType'));
      allPromises.push(
        store.dispatch('v2/search/getOfficialPartnerAreas', { query: query, params: route.params }),
      );

      try {
        await Promise.all(allPromises);
      } catch (e) {
        error(new Error(e.message), 404);
      }
    }

    store.commit('global/SET_LOADING', false);

    // await store.dispatch('v2/masters/getPropertyTypes', query.property_group?Number(query.property_group):1);
    let propertyTypes = store.state.v2.masters.propertyTypes;
    let propertyTypeId = store.state.v2.search.propertyTypeId;
    if (propertyTypeId) {
      let propertyType = await propertyTypes.find(function (item) {
        return item.id === Number(propertyTypeId);
      });
      if (propertyType) {
        propertyType = JSON.parse(JSON.stringify(propertyType));
      } else {
        propertyType = '';
      }

      store.commit('v2/masters/set_property_type', propertyType);
    } else {
      store.commit('v2/masters/set_property_type', '');
    }

    console.log('NAMA ROUTE: ' + route.name);
    if (route.name === 'search') {
      const listingType = await store.dispatch(
        'v2/search/convertListingTypeStringToId',
        route.params.listing_type,
      );
      const listingType2 = listingType === 'R' ? 'Disewakan' : 'Dijual';
      const listingType3 = listingType === 'R' ? 'sewa' : 'beli';
      const listingType4 = listingType === 'R' ? 'Sewa' : 'Beli';
      const activePropertyTypes = store.state.v2.search.activePropertyTypes;
      const propertyType = activePropertyTypes && activePropertyTypes.length > 0 ? activePropertyTypes[0] : '';
      const searchTerm = store.state.v2.search.searchTerm;
      const property =
        propertyType && propertyType.name !== null ? propertyType.name.toLowerCase() : 'properti';
      const location = searchTerm
        ? searchTerm
        : 'Jakarta, Bogor, Depok, Tangerang, Bekasi dan Seluruh Indonesia';

      const baseUrl = store.state.global.baseUrl;
      let title = listingType4 + ' ' + property + ' di ' + location + ' dari Rentfix.';
      // if (title.length > 55) title = title.substring(0, 52) + '...';
      title += ' | Rentfix';

      let description =
        'Rentfix.com - ' +
        listingType2 +
        ' ' +
        property +
        ' di ' +
        location +
        ' dari Rentfix dengan harga terjangkau & murah. Cari dan ' +
        listingType3 +
        ' ' +
        property +
        ' di ' +
        location +
        ' yang paling tepat untuk Anda.';
      if (description.length > 310) description = description.substring(0, 307) + '...';
      description += ' | Rentfix';

      const head = {
        title: title,
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: description,
          },
          {
            hid: 'og-description',
            property: 'og:description',
            content: description,
          },
        ],
        link: [
          {
            rel: 'canonical',
            href: baseUrl + route.fullPath,
            id: 'canonical',
          },
        ],
      };
      store.commit('head/SET_CONTENT', head);
    } else if (route.name === 'search-user-listing') {
      const owner = await store.dispatch('v2/profile/getOwner', route.params.userUuid);
      const name = owner.fullname;
      const baseUrl = store.state.global.baseUrl;
      const ogImage = owner.user_photo;
      const title = 'Kumpulan listing properti ' + name + ' | Rentfix';
      const description =
        'Rentfix.com - ' +
        name +
        ' bekerja sama dengan Rentfix untuk membantu Anda melakukan proses sewa properti mulai ' +
        'dari rumah, apartemen, ruko, kantor, gudang, kios, booth, coworking space, dan ruang pertemuan di ' +
        'seluruh Indonesia. | Rentfix';
      const head = {
        title: title,
        meta: [
          { hid: 'og-url', property: 'og:url', content: baseUrl + route.fullPath },
          { hid: 'og-type', property: 'og:type', content: 'article' },
          {
            hid: 'og-title',
            property: 'og:title',
            content: title,
          },
          {
            hid: 'og-description',
            property: 'og:description',
            content: description,
          },
          { hid: 'og-site-name', property: 'og:site_name', content: 'Rentfix' },
          {
            hid: 'og-image',
            property: 'og:image',
            content: ogImage,
          },
          { hid: 'og-image-width', property: 'og:image:width', content: '450' },
          { hid: 'og-image-height', property: 'og:image:height', content: '450' },
          {
            hid: 'description',
            name: 'description',
            content: description,
          },
        ],
        link: [
          {
            rel: 'canonical',
            href: baseUrl + route.fullPath,
            id: 'canonical',
          },
        ],
      };
      store.commit('head/SET_CONTENT', head);
    } else if (route.name === 'listing-partner') {
      store.commit('v2/listingPartner/SET_LISTING_PARTNER_URL', route.params.listingPartnerUrl);
      await store.dispatch('v2/listingPartner/getListingPartnerDetail');
      const name = store.state.v2.listingPartner.name;
      const baseUrl = store.state.global.baseUrl;
      const ogImage = store.state.v2.listingPartner.profilePictureUrl;
      const title = name + ' | Listing Partner Rentfix';
      const description =
        'Rentfix.com - ' +
        name +
        ' bekerja sama dengan Rentfix untuk membantu Anda melakukan proses sewa properti mulai dari ' +
        'rumah, apartemen, ruko, kantor, gudang, kios, booth, coworking space, dan ruang pertemuan di seluruh ' +
        'Indonesia. - Rentfix';
      const head = {
        title: title,
        meta: [
          { hid: 'og-url', property: 'og:url', content: baseUrl + route.fullPath },
          { hid: 'og-type', property: 'og:type', content: 'article' },
          {
            hid: 'og-title',
            property: 'og:title',
            content: title,
          },
          {
            hid: 'og-description',
            property: 'og:description',
            content: description,
          },
          { hid: 'og-site-name', property: 'og:site_name', content: 'Rentfix' },
          {
            hid: 'og-image',
            property: 'og:image',
            content: ogImage,
          },
          { hid: 'og-image-width', property: 'og:image:width', content: '450' },
          { hid: 'og-image-height', property: 'og:image:height', content: '450' },
          {
            hid: 'description',
            name: 'description',
            content: description,
          },
        ],
        link: [
          {
            rel: 'canonical',
            href: baseUrl + route.fullPath,
            id: 'canonical',
          },
        ],
      };
      store.commit('head/SET_CONTENT', head);
    } else {
      // store.commit('head/RESTORE_DEFAULT_VALUE', route);
    }
  },
  async mounted() {
    this.mountSearch();
  },
  methods: {
    async mountSearch() {
      if (this.$store.state.global.loggedIn) {
        this.$store.dispatch('v2/search/getShortlists');
      }
    },
  },
};
</script>
