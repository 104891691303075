<template>
  <section class="search--content">
    <client-only>
      <div class="top--part">
        <h2 class="title--text">
          <span class="bold">
            {{ metaListing ? metaListing.total : '' }}
          </span>
          {{
            activePropertyTypes && activePropertyTypes.length > 0 ? activePropertyTypes[0].name : $t('general.property')
          }}
          {{ listingType === 'R' ? $t('general.availableR') : $t('general.availableS') }}
        </h2>
        <div class="sort">
          <div class="filter--button" @click="openFilterSidebar()" v-if="$mq === 'xs'">
            <img src="@/assets/img/icons/filter.svg" />
            <div>Filter</div>
          </div>
          <div class="sort--text">{{ $t('general.filter.sort') }}</div>
          <v-select
            :value="activeSortBy"
            @input="inputSortByHandler"
            :items="sortBys"
            :item-text="sortByLabel"
            item-value="id"
            class="dropdown basic--dropdown"
            solo
            hide-details
          ></v-select>
        </div>
      </div>
    </client-only>
    <div class="bottom--part">
      <div class="premier--listing-wrapper">
        <div v-for="(listing, index) in premierListings" :key="index" class="listing--item">
          <card-listing :listing="listing" :premier="true" :allowMultiImage="true"></card-listing>
        </div>
      </div>
      <div class="card--listing-wrapper">
        <div v-for="listing in listings" :key="listing.uuid" class="listing--item">
          <card-listing :listing="listing" :allowMultiImage="true"></card-listing>
        </div>
        <div v-for="project in projects" :key="project.uuid" class="listing--item">
          <card-project-partner
            :projectPartner="project"
            :allowMultiImage="true"
          ></card-project-partner>
        </div>
        <div v-for="area in officialPartnerAreasData" :key="area.uuid" class="listing--item">
          <card-official-partner
            :officialPartner="area"
            :allowMultiImage="false"
            :key="area.uuid"
          />
        </div>
      </div>
      <!--      <pagination-->
      <!--        v-if="searchType === 2 && metaListing"-->
      <!--        :push-state="false"-->
      <!--        :meta="metaListing"-->
      <!--        @changePage="getSearch"-->
      <!--        :maxVisibleButtons="5"-->
      <!--      />-->
      <!--      <pagination-->
      <!--        v-else-if="searchType === 1 && metaProject"-->
      <!--        :push-state="false"-->
      <!--        :meta="metaProject"-->
      <!--        @changePage="getSearch"-->
      <!--        :maxVisibleButtons="5"-->
      <!--      />-->
      <pagination
        v-if="!isOfficialPartner && metaListing"
        :push-state="false"
        :meta="metaListing"
        @changePage="getSearch"
        :maxVisibleButtons="5"
      />
      <pagination
        v-else-if="isOfficialPartner && officialPartnerAreasMeta"
        :push-state="false"
        :meta="officialPartnerAreasMeta"
        @changePage="getSearch"
        :maxVisibleButtons="5"
      />
    </div>
  </section>
</template>

<script>
import CardListing from '@/components/utils/card-listing';
import CardOfficialPartner from '@/components/utils/card-official-partner';
const CardProjectPartner = () => import('@/components/utils/card-project-partner');
const Pagination = () => import('@/components/utils/fractal-pagination');
import { mapState, mapGetters } from 'vuex';
export default {
  name: 'search-content',
  components: {
    CardListing,
    CardOfficialPartner,
    CardProjectPartner,
    Pagination,
  },
  computed: {
    ...mapState({
      sortBys: (state) => state.v2.search.sortByOptions,
      metaListing: (state) => state.v2.search.meta,
      listings: (state) => state.v2.search.listings,
      metaProject: (state) => state.v2.search.metaProject,
      projects: (state) => state.v2.search.projects,
      premierListings: (state) => state.v2.search.premierListings,
      listingType: (state) => state.v2.search.listingType,
      activePropertyTypes: (state) => state.v2.search.activePropertyTypes,
      searchType: (state) => state.v2.search.searchType,
      isOfficialPartner: (state) => state.v2.search.isOfficialPartner,
      officialPartnerAreasData: (state) => state.v2.search.officialPartnerAreasData,
      officialPartnerAreasMeta: (state) => state.v2.search.officialPartnerAreasMeta,
    }),
    ...mapGetters({
      activeSortBy: 'v2/search/activeSortBy',
    }),
  },
  methods: {
    sortByLabel(value) {
      return this.$i18n.t(`general.filter.${value.name}`);
    },
    inputSortByHandler(value) {
      let sortBy = this.sortBys.find(function (item) {
        return item.id === value;
      });
      this.$store.commit('v2/search/SET_SORT_BY', sortBy);
      this.$store.dispatch('v2/search/goToSearchPage', {
        router: this.$router,
        params: this.$route.params,
      });
    },
    getSearch(page) {
      this.$store.commit('v2/search/SET_PAGE', page ? page : 1);
      this.$store.dispatch('v2/search/goToSearchPage', {
        router: this.$router,
        params: this.$route.params,
      });
    },
    openFilterSidebar() {
      this.$store.commit('v2/search/SET_ACTIVE_FILTER_SIDEBAR', true);
    },
  },
};
</script>
